import React from 'react'
import Layout from '../components/layout'
import Menu from '../components/menu'
const About = () => (
  <div>
    <Menu />
    <Layout>
      <h1>About PhoeniX</h1>
      <p>
        PhoeniX is a Front-end/Web Developer, who also happens to be in an
        one-sided relationship with JavaScript
      </p>
    </Layout>
  </div>
)

export default About
